@use './assets/styles/colors.scss' as *;
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  scrollbar-color:  $primary-purple-light-2 #FFF;
}

/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

/**
  * 1) All colors in typography must be referenced via mixins.
  * 2) All font weights should be addressed numerically.
  * 3) Texts with a font-size greater than 20px must have a max-width of 1000px.
  * 4) Texts with a font-size of 20px or smaller must have a max-width of 800px.
  * 5) By default texts have a line-height of 1.5 (which is inherited from
  *    `body`).
  */

@import url('https://fonts.googleapis.com/css?family=Assistant:200,300,400,500,600,700,800&display=swap');

$primary-font: 'Assistant', 'Helvetica Neue', Helvetica, Arial, sans-serif;

/* ==========================================================================
   #BASE
   ========================================================================== */
/**
    * 1. Ensure that transitions only occur after page loads:
    *    https://css-tricks.com/transitions-only-after-page-load/
    * 2. Font smoothing in Webkit and Firefox:
    *    https://davidwalsh.name/font-smoothing
    * 3. Ensure the page always fills at least the entire height of the
    *    viewport.
    */

.preload * {
  /* [1] */
  transition: none !important;
}

html {
  -webkit-font-smoothing: antialiased; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  //height: 100%;
  min-height: 100vh; /* [3] */
  overflow-x: hidden;
  background-color: $neutrals-white !important;

  &.has-scroll-blocked {
    overflow: hidden;
  }
}

$base-font-size: 14px;
$base-font-color: $neutrals-grey-06;
$base-font-line-height: 1.5;
$base-font-weight: 400;

body {
  height: 100%;
  font-family: $primary-font;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-font-line-height;
  color: $base-font-color;
}

// TYPOGRAPHY
.display {
  //font-family: 'Assistant Bold', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5%;
}

.title {
  //font-family: 'Assistant Bold', sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.5%;
}

.subtitle {
  //font-family: 'Assistant SemiBold', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 26.16px;
  letter-spacing: -0.5%;
}

.body {
  //font-family: 'Assistant Regular', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5%;
}

.highlight {
  //font-family: 'Assistant SemiBold', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 23.54px;
  letter-spacing: -0.5%;
}

.caption {
  //font-family: 'Assistant Regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

button[type='button'],
input[type='button'],
button[type='submit'],
input[type='submit'] {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.5%;
}

.link {
  //font-family: 'Assistant SemiBold', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: -0.5%;
}

.pickerText {
  //font-family: 'Assistant Regular', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.5%;
}

.subtitle1-3 {
  //font-family: 'Assistant SemiBold', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.93px;
  letter-spacing: -0.5%;
}

input[type='button'],
button[type='button'],
input[type='submit'],
button[type='submit'] {
  border-radius: 40px;

  &.primary {
    color: white;
    background-color: $primary-purple;
    border-color: $primary-purple !important;

    &:hover {
      background-color: #533B76;
    }
  }

  &.secondary {
    border-color: $primary-purple !important;
    color: $primary-purple;

    &:hover {
      background-color: $neutrals-grey-01;
    }
  }

  &:focus {
    outline: none !important;
  }
}

input,
select,
textarea {
  font-family: $primary-font;
  border-color: $neutrals-grey-02 !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: $neutrals-grey-01 !important;
  }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: black !important;
}

input[type="button"],
button[type="button"],
input[type="submit"],
button[type="submit"] {
  font-family: $primary-font;
  border-color: $neutrals-grey-03 !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &.secondary:disabled {
    background-color: transparent !important;
    opacity: 30%;
  }

  &.primary:disabled {
    background-color: $primary-purple !important;
    opacity: 30%;
  }
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid $neutrals-grey-02;
  background-color: $neutrals-white;

  &:checked {
    background-color: $primary-purple;
  }

  &.disabled {
    opacity: 0.3;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.divider-horizontal {
  width: 1px;
  height: 36.5px;
  background-color: $neutrals-grey-02;
}

.select2-selection {
  width: 100%;
  border-color: $neutrals-grey-02 !important;
  align-items: center;

  span:first-child {
    padding-right: 20px;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: $neutrals-grey-01 !important;
  }
}

.select2 {
  input::placeholder {
    color: $neutrals-grey-03 !important;
  }
}

.select2-dropdown {
  width: 100% !important;
  margin: 0 20px 20px 0px !important;
  border-color: $neutrals-grey-02 !important;
}

.select2-results__option--highlighted {
  background-color: $primary-purple-light !important;
  color: white !important;
}

.select2-selection__arrow:before {
  content: url("./assets/images/arrow-down.svg") !important;
  border-color: transparent !important;
  border-style: solid !important;
  border-width: 5px 4px 0 !important;
  margin-right: 20px !important;
  height: auto !important;
  width: auto !important;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}

select:not([size]) {
  background-image: url("./assets/images/arrow-down.svg") !important;
  // background-position: right 0.75rem center;
  // background-repeat: no-repeat;
  // background-size: 0.75em 0.75em;
  // padding-right: 2.5rem;
  // -webkit-print-color-adjust: exact;
  // print-color-adjust: exact;
}

.select2-selection {
  height: 40px !important;
}

.select2-results__option {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.select2-selection span:first-child {
  padding: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background: $neutrals-grey-01 !important;
}

.gm-style .gm-style-iw-c {
  flex-direction: row-reverse;
}

.gm-style .gm-style-iw-d {
  align-content: center;
  overflow: auto !important;
}

.select2-selection__placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
}